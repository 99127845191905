import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/main',
        name: 'app',
        component: App,
        children: [
            {
                path: '/specials',
                name: 'specials',
                component: () => import('./components/SpecialSales.vue')
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/crud',
                name: 'crud',
                component: () => import('./pages/CrudDemo.vue')
            },
            {
                path: '/cardData',
                name: 'cardData',
                component: () => import('./components/CardData.vue')
            },
            {
                path: '/registerPatient',
                name: 'registerPatient',
                component: () => import('./pages/RegisterPatient.vue')
            },
            {
                path: '/updatePatient',
                name: 'updatePatient',
                component: () => import('./pages/UpdatePatient.vue')
            },
            {
                path: '/sendMail',
                name: 'sendMail',
                component: () => import('./pages/SendMail')
            },
            {
                path: '/reporte',
                name: 'reporte',
                component: () => import('./pages/Reporte.vue')
            },
            {
                path: '/orderReport',
                name: 'orderReport',
                component: () => import('./pages/Reports/OrderReport.vue')
            },
            {
                path: '/customerHistory',
                name: 'customerHistory',
                component: () => import('./pages/Reports/CustomerHistory.vue')
            },

            {
                path: '/advertisingCampaign',
                name: 'advertisingCampaign',
                component: () => import('./pages/AdvertisingCampaign.vue')
            },
            {
                path: '/addCampaign',
                name: 'addCampaign',
                component: () => import('./components/AdvertisingCampaign/AddCampaign.vue')
            },
            {
                path: '/editCampaign/:idAdvertisingCampaign',
                name: 'editCampaign',
                component: () => import('./components/AdvertisingCampaign/EditCampaign.vue'),
                props: true
            },


        ]
    },
    {
        path: '',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
