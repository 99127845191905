/* eslint-disable */
const globalMixin = {
    data() {
        return {

        }
    },
    methods: {
        openLoading(title) {
            Swal.fire({
                title,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        },
        isTokenExpired() {
            const expiry = (JSON.parse(Buffer.from(localStorage.userToken.split('.')[1], 'base64'))).exp;
            console.log(expiry)
            console.log('date: ' + (Math.floor((new Date).getTime() / 1000)))

            if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
                window.localStorage.clear()
                router.push('/')
            }

        },
        logout() {
            window.localStorage.clear()
            router.push('/')
        }
    }
}

export default globalMixin